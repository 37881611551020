import clsx from "clsx";
import React from "react";
import { DialogContent, DialogOverlay } from "@reach/dialog";
import { Icon } from "~/components/ui/icons";
import { H4 } from "./typography";
import { Button } from "~/components/ui/button";
import { useLocation } from "@remix-run/react";

interface Props {
	id?: string;
	isOpen?: boolean;
	onClose?: () => void;
	children: React.ReactNode;
	showCloseButton?: boolean;
	onCloseText?: string;
	ariaLabel: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	initialFocusRef?: React.RefObject<any>;
	className?: string;
}

const modalQueryKey = "m";

type ContextValues = {
	isOpen: boolean;
	close: () => void;
} | null;

const DialogContext = React.createContext<ContextValues>(null);

function useDialogContext() {
	const values = React.useContext(DialogContext);

	if (!values) {
		throw new Error("useDialogContext must be used within a DialogContext");
	}

	return values;
}

export function Modal({
	isOpen: controlledOpen,
	onClose,
	children,
	id,
	ariaLabel,
	initialFocusRef,
	className,
}: Props) {
	// show modal when the param contains the modal id
	const location = useLocation();
	const modalParam = new URLSearchParams(location.search).get(modalQueryKey);
	const isUrlWithModal = modalParam === id;

	const [showModal, setShowModal] = React.useState(isUrlWithModal);

	// Check if the modal state is controlled by the parent component
	// if not, the modal will handle its state internally
	const openIsControlled = controlledOpen !== undefined;

	const isOpen = openIsControlled ? controlledOpen : showModal;

	function close() {
		if (!openIsControlled) {
			setShowModal(false);
		}

		if (onClose) {
			onClose();
		}
	}

	return (
		<DialogOverlay
			id={id}
			isOpen={isOpen}
			className="z-50 p-5"
			style={{ background: "rgba(0, 0, 0, 0.55)" }}
			onDismiss={close}
			initialFocusRef={initialFocusRef}
		>
			<DialogContent
				aria-label={ariaLabel}
				className={clsx(
					"shadow relative my-[5vh] flex max-h-modal w-fit min-w-modal max-w-modal flex-col overflow-y-auto rounded p-6 lg:p-7",
					className
				)}
			>
				<DialogContext.Provider value={{ isOpen, close }}>
					<button className="absolute right-3 top-3" onClick={close}>
						<span className="sr-only">Close modal</span>
						<Icon name="close-button" color="secondary" />
					</button>
					{children}
				</DialogContext.Provider>
			</DialogContent>
		</DialogOverlay>
	);
}

function Header({
	title,
	subtitle,
	className,
	focusable,
}: {
	title: string;
	subtitle?: React.ReactNode;
	className?: string;
	focusable?: boolean;
}) {
	return (
		<div className={clsx("mb-6 lg:mb-7", className)}>
			<div className={"outline-none"} tabIndex={focusable ? 0 : -1}>
				<H4 className="mb-2">{title}</H4>
			</div>
			{subtitle}
		</div>
	);
}

function Content({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<div className={clsx("h-full overflow-y-auto", className)}>{children}</div>
	);
}

type FooterProps = {
	className?: string;
	showCloseButton?: boolean;
	closeText?: string;
	children?: React.ReactNode;
};

export function Footer({
	showCloseButton = true,
	closeText,
	children,
	className,
}: FooterProps) {
	const { close } = useDialogContext();

	return (
		<div className={clsx("mt-8 flex gap-6", className)}>
			{children}
			{showCloseButton ? (
				<Button onClick={close} variant="secondary">
					{closeText ? closeText : "Close"}
				</Button>
			) : null}
		</div>
	);
}

Modal.Header = Header;
Modal.Content = Content;
Modal.Footer = Footer;
